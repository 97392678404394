// Put any external JS or custom JS you wish for the theme here.

import './navigation'

(function (w, $) {

    const fincorpClickStepNinjaForm = () => {
        $('input[type=number]').attr('type', 'range');
        
        let step = 0,
            stepItem = $('.form-step .nf-breadcrumbs li'),
            lenghtItem = stepItem.length,
            btnExit = $('.fincorp-exist-calc'),
            btnCustomBack = $('.fincorp-prev-calc'),
            error = $('.nf-form-errors');

        // Step Next
        $(document).on("click", ".nf-next-item .nf-next", function () {
            btnExit.hide();
            btnCustomBack.show();
            if (error.find('.nf-error-msg').length) return;
            if (step == lenghtItem - 1) return;
            stepItem.eq(step + 1).addClass("active-li");
            stepItem.eq(step).addClass("actived-li");

            var divElement = document.querySelector('.nf-breadcrumb-titles');
            var pElements = divElement.querySelectorAll('p');
            pElements[step + 1].classList.add('active-li');
            pElements[step].classList.add('actived-li');

            step++;

        });

        // Step Previous
        $(document).on("click", ".nf-previous-item .nf-previous", function () {
            stepItem.eq(step).removeClass("active-li");
            stepItem.eq(step - 1).removeClass("actived-li");

            var divElement = document.querySelector('.nf-breadcrumb-titles');
            var pElements = divElement.querySelectorAll('p');
            pElements[step].classList.remove('active-li');
            pElements[step-1].classList.remove('actived-li');

            step--;
            if (step == 0) {
                btnExit.show();
                btnCustomBack.hide();
            }

            let data_dropdown = $('body').data('select');
            $('.dropdownfield-wrap').find('select').val(data_dropdown).change();
            let selectedOptionText = $('div[data-value="' + data_dropdown + '"]').html();
            $('.dropdownfield-wrap').find('.selected-item').html(selectedOptionText).removeClass("arrowanim").addClass("active-color");
        });

        $('.fincorp-prev-calc').click(function(){
            $('.nf-previous').click();
        })
    }

    //clone form steps
    const fincorpCloneFormStep = () => {
        $(".nf-breadcrumbs").clone().appendTo(".form-step");
        $(".form-step .nf-breadcrumbs li").append("<span></span>");
        $(".form-step .nf-breadcrumbs li").each(function(){
            const title = $(this).find('a').text();
            $(".form-step .nf-breadcrumb-titles").append("<p>" + title + "</p>");
        });
    }

    //click exist calculator
    const fincorpClickExistCalc = () => {
        $(".fincorp-exist-calc-link").on("click", function () {
            if (document.referrer) {
                document.location.href = document.referrer
            } else {
                document.location.href = "/";
            }
        });
    }

    //add commas to field number
    const fincorpAddCommasNumberFields = () => {
        //loan of fields
        $(document).on('keyup', '.loans-of-fields input', function (e) {
            // skip for arrow keys
            if (e.which >= 37 && e.which <= 40) return;

            $(this).val(function (index, value) {
               let numberVal = parseInt(value.replace(",", ""));
                if (numberVal > 20000){
                    $(this).parents('.loans-of-fields').find('.nf-error-wrap.nf-error').html('<div class="nf-error-msg nf-error-number-min">Number Max Error</div>');
                    $(".nf-next-item .nf-next").attr('disabled','disabled');
                }else if(numberVal < 200){
                    $(this).parents('.loans-of-fields').find('.nf-error-wrap.nf-error').html('<div class="nf-error-msg nf-error-number-min">Number Min Error</div>');
                    $(".nf-next-item .nf-next").attr('disabled','disabled');
                }else{
                    $(this).parents('.loans-of-fields').find('.nf-error-msg.nf-error-number-min').remove();
                    $(".nf-next-item .nf-next").removeAttr('disabled');
                }

                return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
        });

        //repaid fields
        $(document).on('keyup', '.repaidover-field input', function (e) {
            // skip for arrow keys
            if (e.which >= 37 && e.which <= 40) return;

            $(this).val(function (index, value) {
               let numberVal = parseInt(value.replace(",", ""));
                if (numberVal > 30){
                    $(this).parents('.repaidover-field').find('.nf-error-wrap.nf-error').html('<div class="nf-error-msg nf-error-number-min">Number Max Error</div>');
                    $(".nf-next-item .nf-next").attr('disabled','disabled');
                }else if(numberVal < 5){
                    $(this).parents('.repaidover-field').find('.nf-error-wrap.nf-error').html('<div class="nf-error-msg nf-error-number-min">Number Min Error</div>');
                    $(".nf-next-item .nf-next").attr('disabled','disabled');
                }else{
                    $(this).parents('.repaidover-field').find('.nf-error-msg.nf-error-number-min').remove();
                    $(".nf-next-item .nf-next").removeAttr('disabled');
                }

                return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
        });
    }
    
    const fincorpLoadAnimationArrow = () => {
        if (!$('.fincorp-element-arrow-animation').length) return;
        let waypoint = new Waypoint({
            element: document.getElementsByClassName('fincorp-element-arrow-animation'),
            handler: function () {
                this.destroy();
                $('.fincorp-element-arrow-animation').addClass('animationed')
            },
            offset: 'bottom-in-view',
        });
    }
 
    // Document ready
    $(document).ready(function () {
        fincorpLoadAnimationArrow();
    });

    // Browser load completed 
    $(w).load(function () {
        AOS.init({ once: true, disable: 'mobile' });
    });

    jQuery(document).on('nfFormReady', function () {
        fincorpCloneFormStep();
        fincorpClickStepNinjaForm();
        fincorpClickExistCalc();
        fincorpAddCommasNumberFields();
    })
})(window, jQuery);
