/**
 * fincorp
 *
 */

; ((w, $) => {
    'use strict'
    const DesktopDropdownMenu = () => {
        $('.main-menu').on('click', '.nav-link.dropdown-toggle', function (e) {
            if($(this).parent().hasClass('nav-actived')){
                $(this).parent().removeClass('nav-actived');
            }else{
                $('.menu-item').removeClass('nav-actived');
                $(this).parent().toggleClass('nav-actived');
            }
        });

        $('body').click(function (e) {
            var $target = $(e.target);
            if (!$target.is(".nav-link.dropdown-toggle")) {
                $('.menu-item').removeClass('nav-actived');
            }
        });
    }

    const MobileToggleMenu = () => {
        $('.main-header').on('click', '.header-nav-mobile-toggle-open', function (e) {
            e.preventDefault();
            $('body').toggleClass('__menu-mobi-open')
        })

        $('.main-header').on('click', '.header-nav-mobile-toggle-close', function (e) {
            e.preventDefault();
            $('body').toggleClass('__menu-mobi-open')
        })

        //Mobile submenu
        $('.header-nav-mobile .main-menu').on('click', '.nav-link.dropdown-toggle', function (e) {
            e.preventDefault();
            e.stopPropagation()
            // $('.main-header').find('.fincorp-dropdown-menu').slideUp();
            $('.main-header').find('.nav-item').removeClass('is-active');
            $('.main-header').find('.nav__link').removeClass('is-active');
            const $fincorp_dropdown_menu = $(this).parent().find('.fincorp-dropdown-menu');
            $(this).parent().toggleClass('is-active');
            $(this).toggleClass('is-active');
            $fincorp_dropdown_menu.slideToggle();

        })
    }

    const fincorpHeaderScroll = () => {
        if ($(window).width() < 767) return;
        if (!document.getElementById("site-header")) return;
        let loggedin = document.getElementsByClassName('logged-in');

        let prevScrollpos = window.pageYOffset;

        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos || currentScrollPos < 10) {
                if (loggedin.length > 0)
                    document.getElementById("site-header").style.top = "32px";
                else
                    document.getElementById("site-header").style.top = "0";
                $('body').addClass('fincorp-sticky')
            } else {
                if ($(window).width() < 767)
                    document.getElementById("site-header").style.top = "-70px";
                else
                    document.getElementById("site-header").style.top = "-112px";
                $('body').removeClass('fincorp-sticky')
            }
            prevScrollpos = currentScrollPos;
        }
    }


    const Ready = () => {
        MobileToggleMenu();
        DesktopDropdownMenu();
    }

    // Browser load completed
    $(w).load(function () {
        fincorpHeaderScroll();
    });
    $(Ready)

})(window, jQuery)
